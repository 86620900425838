/**
 *菜单路由
 * meta.title 页面标题
 * meta.hideTitle 是否隐藏面包屑标题
 * meta.isNeedCorpid 菜单是否是企微注册店铺专用
 * meta.isNormal 菜单是否是通用版专用
 * */
const topMenu = [{
    path: '/Index',
    name: 'Index',
    component: () => import('@/views/Dashboard/Index'),
    meta: {
      title: '首页',
      hideTitle: true,
    },
  },
  {
    path: '/BookReview',
    name: 'BookReview',
    component: () => import('@/views/Dashboard/BookReview'),
    meta: {
      title: '预订回访',
      hideTitle: true,
    },
  },
  {
    path: '/Information',
    name: 'Information',
    component: () => import('@/views/Dashboard/Information'),
    meta: {
      title: '消息通知',
      hideTitle: true,
    },
  },
  {
    path: '/StoreList',
    name: 'StoreList',
    component: () => import('@/views/Store/List'),
    meta: {
      title: '选择店铺',
      hideTitle: true,
    },
  },
  {
    path: '/PackageOrder',
    name: 'PackageOrder',
    component: () => import('@/views/Dashboard/PackageOrder'),
    meta: {
      title: '套餐订单',
      hideTitle: true,
    },
  },
  {
    path: '/Store',
    name: 'Store',
    component: () => import('@/views/Store/Index'),
    meta: {
      title: '店铺设置',
    },
  },
  {
    path: '/RoomSetting',
    name: 'RoomSetting',
    component: () => import('@/views/RoomSetting/Index'),
    meta: {
      title: '餐位设置',
    },
  },
  {
    path: '/MoneyManage',
    name: 'MoneyManage',
    component: () => import('@/views/MoneyManage/Index'),
    meta: {
      title: '资金管理',
    },
  },
  {
    path: '/MobileWorkbench',
    name: 'MobileWorkbench',
    component: () => import('@/views/MobileWorkbench/Index'),
    meta: {
      title: '移动工作台',
    },
  },
  {
    path: '/Performance',
    name: 'Performance',
    component: () => import('@/views/Performance/Index'),
    meta: {
      title: '绩效设置',
      isNeedCorpid: true,
    },
  },
  {
    path: '/Employee',
    name: 'Employee',
    component: () => import('@/views/EmployeeList/Index'),
    meta: {
      title: '员工列表',
      isNeedCorpid: true,
    },
  },
  {
    path: '/Position',
    name: 'Position',
    component: () => import('@/views/Position/Index'),
    meta: {
      title: '餐厅职位',
      isNeedCorpid: true,
    },
  },
  {
    path: '/BookingStatistics',
    name: 'BookingStatistics',
    component: () => import('@/views/BookingStatistics/Index'),
    meta: {
      title: '预订统计',
    },
  },
  {
    path: '/DedicatedServiceManager',
    name: 'DedicatedServiceManager',
    component: () => import('@/views/DedicatedServiceManager/Index'),
    redirect: '/DedicatedServiceManager/ServicePerformance',
    meta: {
      title: '专属服务经理',
      hideTitle: true,
      isNormal: true,
    },
    children: [{
        path: '/DedicatedServiceManager/ServiceManagerList',
        name: 'ServiceManagerList',
        component: () =>
          import('@/views/DedicatedServiceManager/ServiceManagerList'),
        meta: {
          title: '服务经理列表',
          hideTitle: true,
        },
      },
      {
        path: '/DedicatedServiceManager/ServicePosition',
        name: 'ServicePosition',
        component: () => import('@/views/Position/Index'),
        meta: {
          title: '餐厅职位',
          hideTitle: true,
        },
      },
      {
        path: '/DedicatedServiceManager/ServicePerformance',
        name: 'ServicePerformance',
        component: () => import('@/views/Performance/Index'),
        meta: {
          title: '绩效设置',
          hideTitle: true,
        },
      },
      {
        path: '/DedicatedServiceManager/ReserveNote',
        name: 'ReserveNote',
        component: () => import('@/views/DedicatedServiceManager/ReserveNote'),
        meta: {
          title: '预订短信控制',
          hideTitle: true,
        },
      },
    ],
  },
  {
    path: '/Personalization',
    name: 'Personalization',
    component: () => import('@/views/Personalization/Index'),
    redirect: '/Personalization/BaseSetting',
    meta: {
      title: '个性化设置',
      hideTitle: true,
      isNormal: true,
    },
    children: [{
        path: '/Personalization/BaseSetting',
        name: 'OptionsSwitch',
        component: () => import('@/views/Personalization/OptionsSwitch'),
        meta: {
          title: '功能开关',
          hideTitle: true,
        },
      },
      {
        path: '/Personalization/BaseSetting',
        name: 'PersonalSetting',
        component: () => import('@/views/Personalization/PersonalSetting'),
        meta: {
          title: '个性化设置',
          hideTitle: true,
        },
      },
      {
        path: '/Personalization/BaseSetting',
        name: 'MealSetting',
        component: () => import('@/views/Personalization/MealSetting'),
        meta: {
          title: '餐段设置',
          hideTitle: true,
        },
      }
    ]
  },
  {
    path: '/Personalization/OptionsSwitch',
    name: 'OptionsSwitch',
    component: () => import('@/views/Personalization/OptionsSwitch'),
    meta: {
      title: '功能开关',
      isNeedCorpid: true,
    },
  },
  {
    path: '/Personalization/PersonalSetting',
    name: 'PersonalSetting',
    component: () => import('@/views/Personalization/PersonalSetting'),
    meta: {
      title: '个性化设置',
      isNeedCorpid: true,
    },
  },
]
export default topMenu